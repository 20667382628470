
import { SITE_KEY } from '@/services/google-services';
import Confirm from '@/components/core/Confirm.vue';
import WelcomeMixin from '@/mixins/welcome-mixin';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import AuthServices from '@/services/auth-services';

declare const grecaptcha: any;

@Component({
  mixins: [WelcomeMixin]
})
export default class Login extends Vue {
  email = '';
  password = '';
  confirmPassword = '';
  studentSignUpStep = 0;
  promoCode!: string;
  errorMsg!: string;
  infoMsg!: string;
  loading!: boolean;
  joinData!: any;
  joinCode!: string;
  redirected!: boolean;
  localStudentJoinData!: any;
  accountExists!: boolean;
  allowNewStudentAccounts!: boolean;
  isValidStudentJoinCode!: boolean;
  hasStudentJoinCode!: boolean;
  hasJoinCode!: boolean;
  joinTokenSchoolId!: any;
  studentJoinCode!: string;
  requiredRule!: Array<any>;
  emailRules!: Array<any>;
  emailFormatRule!: Array<any>;
  hasErrorMsg!: boolean;
  hasInfoMsg!: boolean;
  teacherId!: any;
  yearId!: any;
  schoolId!: any;
  subjectIds!: Array<any>;
  hasPasswordValidationMsg!: any;
  passwordValidationMsg!: any;
  recaptchaVerified = false;
  recaptchaToken = '';

  emailError = '';
  codeError = '';
  countdown = 0;
  countdownInterval: any;

  // student fields
  firstName = '';
  lastName = '';
  parentEmail = '';
  code = '';
  birthDate = '';
  emailSignUpRules: Array<any> = this.emailRules;
  codeSignUpRules: Array<any> = [() => true];

  signUp!: (user: any) => Promise<any>;
  loadJoinData!: () => Promise<any>;
  doReset!: () => void;
  fru!: (condition: boolean, value: any) => any;

  $refs!: {
    confirm: Confirm;
    recaptcha: any;
    signUpForm: Vue & { validate: () => boolean; resetValidation: () => void };
  };

  validate() {
    return this.$refs.signUpForm.validate();
  }

  @Watch('password')
  onPasswordChange() {
    const { minLength, maxLength, uppercase, lowercase, number, specialChar } = this.passwordRules;
    const checkIdenticalEmail = this.email.includes('@') ? this.email.substring(0, this.email.indexOf('@')) : this.email;

    var passwordRuleCount = 1;
    uppercase.test(this.password) ? passwordRuleCount++ : passwordRuleCount += 0;
    lowercase.test(this.password) ? passwordRuleCount++ : passwordRuleCount += 0;
    number.test(this.password) ? passwordRuleCount++ : passwordRuleCount += 0;
    specialChar.test(this.password) ? passwordRuleCount++ : passwordRuleCount += 0;

    if (this.password.length + 1 <= minLength) {
      this.hasPasswordValidationMsg = true;
      this.passwordValidationMsg = this.$t('passwordValidationMinLength');
    } else if (this.password.length + 1 >= maxLength) {
      this.hasPasswordValidationMsg = true;
      this.passwordValidationMsg = this.$t('passwordValidationMaxLength');
    } else if (checkIdenticalEmail.toLowerCase() === this.password.toLowerCase() || this.email.toLowerCase() === this.password.toLowerCase()) {
      this.hasPasswordValidationMsg = true;
      this.passwordValidationMsg = this.$t('passwordValidationIdenticalEmail');
    } else if (passwordRuleCount <= 3) {
      this.hasPasswordValidationMsg = true;
      this.passwordValidationMsg = this.$t('passwordValidationThreeTypes');
    } else {
      this.hasPasswordValidationMsg = false;
      this.passwordValidationMsg = '';
    }
  }

  get passwordRule() {
    return this.requiredRule.concat([
      () => !this.hasPasswordValidationMsg || this.$t('passwordInvalidFormat')
    ]);
  }

  get passwordRules() {
    return {
      minLength: 8,
      maxLength: 128,
      uppercase: /[A-Z]/,
      lowercase: /[a-z]/,
      number: /\d/,
      specialChar: /[!@#$%^&*()_+\-=[\]{}|']/
    };
  }

  get confirmPasswordRules() {
    return this.requiredRule.concat([
      (v: string) => this.password === v || this.$t('notification12')
    ]);
  }

  get signInPath() {
    if (this.hasPromoCode) {
      return '/auth/v1/login'
    }
    return '/auth/login'
  }

  get hasPromoCode() {
    return CommonUtils.hasText(this.promoCode);
  }

  @Watch('studentSignUpStep')
  onStudentSignUpStepChange() {
    if (this.studentSignUpStep > 1) {
      this.emailSignUpRules = [(email: string) => CommonUtils.hasNoText(this.code) && CommonUtils.hasNoText(email) ? this.$t('emailOrStudentIDRequired') as string : true];
      this.codeSignUpRules = [(code: string) => CommonUtils.hasNoText(this.email) && CommonUtils.hasNoText(code) ? this.$t('emailOrStudentIDRequired') as string : true];
    } else {
      this.emailSignUpRules = this.emailRules;
      this.codeSignUpRules = [];
    }
    this.$refs.signUpForm.resetValidation();
  }

  doCancel() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    if (this.studentSignUpStep > 1) {
      this.studentSignUpStep--;
    } else {
      this.doReset();
      this.studentSignUpStep = 0;
    }
  }

  doContinue() {
    if (this.validate()) {
      this.studentSignUpStep++
    }
  }

  doSSOSignUp(idp: string) {
    window.location.href = AuthServices.getOAuth2CodeAuthorizationUrl() + `&idp=${idp}&src=signup`;
  }

  doSignUp() {
    this.errorMsg = '';
    if (this.validate()) {
      this.loading = true;
      const registerStudent = this.fru(this.hasStudentJoinCode, true);
      const params = {
        email: this.email,
        password: this.password,
        promoCode: this.promoCode,
        registerStudent,
        connect: this.fru(this.accountExists, true),
        token: this.fru(this.hasJoinCode, this.joinCode),
        firstName: this.fru(this.hasStudentJoinCode, this.firstName),
        lastName: this.fru(this.hasStudentJoinCode, this.lastName),
        code: this.fru(this.hasStudentJoinCode, this.code),
        parentEmail: this.fru(this.hasStudentJoinCode, this.parentEmail),
        birthDate: this.fru(this.hasStudentJoinCode, this.birthDate),
        schoolId: this.schoolId,
        subjectIds: this.subjectIds,
        yearId: this.yearId,
        teacherId: this.teacherId,
        recaptcha: this.recaptchaToken || CommonUtils.generateUUID()
      };
      this.signUp(params)
        .then(resp => {
          if (registerStudent) {
            return this.$router.replace({ path: '/plans' });
          } else {
            return this.$router.replace({
              path: '/join',
              query: {
                token: resp.data.idToken,
                referrer: window.location.origin
              }
            });
          }
        })
        .catch(error => {
          this.errorMsg = error.message;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  clearCountdown() {
    this.studentSignUpStep = 0;
    this.countdown = 0;
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  clearInfoMsg() {
    this.clearCountdown();
    this.doReset();
  }

  renderRecaptcha() {
    this.recaptchaVerified = false;
    this.recaptchaToken = '';
    grecaptcha.render(this.$refs.recaptcha, {
      sitekey: SITE_KEY,
      callback: this.onRecaptchaSuccess
    });
  }

  onRecaptchaSuccess(token: string) {
    this.recaptchaVerified = true;
    this.recaptchaToken = token;
  }

  mounted() {
    this.renderRecaptcha();
  }

  beforeDestroy() {
    this.clearCountdown();
  }

  created() {
    this.emailSignUpRules = this.emailRules;
    this.codeSignUpRules = [() => true];
    this.studentSignUpStep = 0;
    this.errorMsg = '';
    this.infoMsg = '';
    this.firstName = '';
    this.lastName = '';
    this.parentEmail = '';
    this.code = '';
    this.birthDate = '';
    this.loadJoinData().then((resp) => {
      if (this.accountExists && !this.redirected) {
        this.clearCountdown();
        this.countdown = 10;
        this.infoMsg = this.$t('welcomeMsg100', {
          name: this.joinData.schoolName,
          action: (this.$t('signUpOrSignInLabel') as string).toLowerCase()
        }) as string;
        this.infoMsg += this.$t('existingAccountRedirectingMsg', { countdown: this.countdown }) as string;
        this.countdownInterval = setInterval(() => {
          this.countdown -= 1;
          if (this.countdown > 0) {
            this.infoMsg = this.$t('welcomeMsg100', {
              name: this.joinData.schoolName,
              action: (this.$t('signUpOrSignInLabel') as string).toLowerCase()
            }) as string;
            this.infoMsg += this.$t('existingAccountRedirectingMsg', { countdown: this.countdown }) as string;
          } else {
            this.clearCountdown();
            this.$router.push({ path: this.signInPath });
          }
        }, 1000);
      } else if (CommonUtils.hasText(this.joinCode)) {
        if (CommonUtils.hasText(this.joinData.emailAddress)) {
          this.email = this.joinData.emailAddress;
        }
        this.infoMsg = this.$t('welcomeMsg100', {
          name: this.joinData.schoolName,
          action: (this.$t('signUpOrSignInLabel') as string).toLowerCase()
        }) as string;
      } else if (CommonUtils.hasText(this.studentJoinCode)) {
        const data = resp.data || {};
        const classNames: any = [];
        if (data.count > 0) {
          for (const i in data.className) {
            classNames.push(`<span style="font-weight: bold;">${data.className[i]} (${data.teacherEmailAddress[i]})</span>`);
          }
        }
        if (this.isValidStudentJoinCode) {
          this.studentSignUpStep = 1;
          if (this.allowNewStudentAccounts) {
            this.infoMsg = this.$t('welcomeMsg101', {
              codes: classNames.join(', '),
              action: (this.$t('signUpOrSignInLabel') as string).toLowerCase()
            }) as string;
          } else {
            this.clearCountdown();
            this.countdown = 10;
            this.infoMsg = this.$t('allowStudentsInfoMsg', { classNames: classNames.join(', '), countdown: this.countdown }) as string;
            this.countdownInterval = setInterval(() => {
              this.countdown -= 1;
              if (this.countdown > 0) {
                this.infoMsg = this.$t('allowStudentsInfoMsg', { classNames: classNames.join(', '), countdown: this.countdown }) as string;
              } else {
                this.clearCountdown();
                this.$router.push({ path: this.signInPath });
              }
            }, 1000);
          }
        } else {
          this.errorMsg = this.$t('invalidStudentJoinCode') as string;
          this.$store.commit('welcome/reset', null, { root: true });
        }
      }
      return Promise.resolve();
    });
  }
}
